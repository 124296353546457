import React from "react"
import { Box } from "@theme-ui/components"

export default ({ small = false, sx = {}, children, ...props }) => (
  <Box
    sx={{
      mx: "auto",
      px: 5,
      width: "100%",
      maxWidth: small ? "container" : "largeContainer",
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
)
