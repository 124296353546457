import React from "react"
import { Flex as GridRow, Box as GridCol } from "reflexbox"
import { Flex, Box, Image, Link, Text } from "@theme-ui/components"

import Container from "./container"

import phone from "../images/phone.gif"
import email from "../images/email.gif"

export default () => (
  <Flex
    as="footer"
    sx={{
      flexDirection: "column",
      justifyContent: "flex-start",
      bg: "#F6F7FB",
    }}
  >
    <Container small sx={{ pt: 11, pb: 5 }}>
      <GridRow mb={9}>
        <GridCol width={[1, 1 / 2, 1 / 3]}>
          <Text fontSize={20} mb={4}>
            NYC Office
          </Text>
          <Text fontSize={20} mb={1}>
            6 St Johns Ln, New York, NY 10013
          </Text>
          <Text fontSize={20}>+1 650 678-1500</Text>
        </GridCol>
        <GridCol width={[1, 1 / 2, 1 / 3]}>
          <Text fontSize={20} mb={4}>
            Prague Office
          </Text>
          <Text fontSize={20} mb={1}>
            Namesti Interbrigady 6, Prague, 16000
          </Text>
          <Text fontSize={20}>+420 776 318301</Text>
        </GridCol>
      </GridRow>
      <Flex sx={{ width: "100%", height: "1px", bg: "#C4C4C4", mb: 5 }} />
      <Flex
        sx={{
          flexDirection: ["column", "row"],
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: [2, 0],
            mr: [0, 10],
          }}
        >
          <Image src={phone} sx={{ width: "40px", mr: 2 }} />
          <Link href="tel:+1 650 678-1500">+1 650 678-1500</Link>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Image src={email} sx={{ width: "32px", mr: 2 }} />
          <Link href="mailto:info@humidresearch.com">
            info@humidresearch.com
          </Link>
        </Box>
      </Flex>
    </Container>
  </Flex>
)
