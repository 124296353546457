import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => (
  <>
    <Global
      styles={() => ({
        body: {
          margin: 0,
          background: "#e6e5da",
        },
      })}
    />
    <Header />
    {children}
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
