import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Flex, Box, Image, Link } from "@theme-ui/components"

import Separator from "./separator"
import Container from "./container"

import logo from "../images/logo.svg"
import phone from "../images/phone.gif"
import email from "../images/email.gif"

export default () => (
  <Container as="header">
    <Flex sx={{ py: [2, 4], alignItems: "center", fontSize: 16 }}>
      <Box sx={{ display: ["none", "flex"], alignItems: "center" }}>
        <Image src={phone} sx={{ width: "40px", mr: 2 }} />
        <Link href="tel:+1 650-678-1500">+1 650-678-1500</Link>
      </Box>
      <Box sx={{ flex: 1, textAlign: "center" }}>
        <GatsbyLink to="/">
          <Image sx={{ width: ["100px", "150px", "200px"] }} src={logo} />
        </GatsbyLink>
      </Box>
      <Box sx={{ display: ["none", "flex"], alignItems: "center" }}>
        <Image src={email} sx={{ width: "32px", mr: 2 }} />
        <Link href="mailto:info@humidresearch.com">info@humidresearch.com</Link>
      </Box>
    </Flex>
    <Separator sx={{ mb: 8 }} />
  </Container>
)
